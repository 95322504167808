/* Footer.module.scss */

/* Importing the design tokens */
@import '../styles/design-tokens.scss';  /* Adjust path if needed */

/* Footer styles with Pre-header resemblance */
.footer {
    background-color: $footer-bg; /* Use the $footer-bg variable from design tokens */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center-align elements */
    align-items: center;
    text-align: center;
    gap: 15px; /* Space between sections */
    padding: 20px 15px; /* Uniform padding */
    box-shadow: $footer-shadow; /* Use $footer-shadow variable */
    margin: 0;

}
  
/* Footer text adjustments */
.footerText {
    color: $text-light; /* Use the $text-light variable */
    font-weight: 400;
    font-size: 14px; /* Slightly larger and readable font size */
    line-height: 1.5; /* Improved line spacing */
    margin: 0;
  
}
  
/* Footer Links section */
.footerLinks {
    display: flex;
    justify-content: center; /* Center links */
    gap: 20px; /* Add space between links */
    list-style: none;
    margin: 0;
    padding: 0;
}
  
.footerLinks a {
    color: $text-light; /* Use $text-light variable */
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    transition: $transition; /* Use $transition variable */
}
  
.footerLinks a:hover {
    color: $primary-color; /* Use $primary-color variable */
}
  
/* Social media section */
.footerSocialMedia {
    display: flex;
    justify-content: center;
    gap: 15px; /* Space between icons */
}
  
.footerSocialMedia a {
    color: $text-light; /* Use $text-light variable */
    font-size: 16px; /* Icon size */
    transition: $transition; /* Use $transition variable */
}
  
.footerSocialMedia a:hover {
    color: $primary-color; /* Highlight on hover with $primary-color */
}
  
/* Responsive styles */
@media screen and (min-width: 768px) {
    .footer {
        flex-direction: row; /* Align content in rows for larger screens */
        justify-content: space-between;
        padding: 20px 30px; /* Adjust padding */
    }
  
    .footerText {
        text-align: left; /* Align text for readability on larger screens */
    }
  
    .footerLinks {
        gap: 25px; /* Increase spacing between links */
    }
}
