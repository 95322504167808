.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}
.heroTitle{
  text-align: center;
  font-size: large;
  font-weight: bold;
}
.mainContainer {
  flex-grow: 1;
  padding: 20px;
}

.serviceContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 95%;
  margin: 0 auto;
  height: auto;
}
.serviceBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}
.serviceItem{
  flex:1;
   padding: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
}
.serviceText{
  flex:1;
  height: 100%;

}
.highlight{
  color: #6b9cd0;
  font-weight: bold;
}
.exploreButtonContainer{
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 20px;
}
.content {

  background-size: cover;
  background-position: center;
  height: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.contentText {
 /* Dark overlay to improve text visibility */
  padding: 40px;
  border-radius: 8px; /* Rounded corners */
  color: #fff;
  text-align: center;
  max-width: 1200px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: black;
}

.contentText h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.contentText h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  font-style: italic;
}

.contentText h4 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 1.6;
}

.serviceSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}


.slick-dots {
  position: absolute;
  bottom: -30px; /* Adjust to move the dots below the slider */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

/* Style for each dot */
.cardContainer {
  display: flex;
  justify-content: space-between; 
  flex-wrap: wrap; 
  gap: 20px; 
}

.footerContainer {
  color: #fff;
  text-align: center;
}

@media (max-width: 600px) {
 
  .contentText {
    padding: 10px;
  }

  .contentText h2 {
    font-size: 16px;
  }

  .contentText h4 {
    font-size: 16px;
  }

  .contentText p {
    font-size: 1.5rem;
  }

  /* Stack cards vertically on smaller screens */
  .cardContainer {
    display: flex;
    flex-direction: column;
    align-items:   center;
    justify-content: center;
  
   
  }
  .serviceSection {
    padding: 0px;;
  }
  
  .cardContainer {
   padding: 0px;
  }
  .serviceBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
  .serviceBoxReverse{
    display: flex;
    flex-direction:column-reverse;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 10px;
  }
}
