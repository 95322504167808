
  .pageContainer {
    background-color: #f9f9f9;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    min-height: 100vh; 
  }
  .ContainerForStep {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    gap: 25px;
  }
  .stepsHeader {
    text-align: center;
    margin-bottom: 30px;
  }
  .stepsHeader h1 {
    font-size: 2.5rem;
    color: #416ea2;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
  }
  .stepsHeader p {
    font-size: 1rem;
    color: #666;
    max-width: 600px;
    margin: 10px auto 0;
    line-height: 1.5;
  }
  .enhancedSection {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    background: linear-gradient(135deg, #f0f4ff, #e8effc);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    gap: 15px;
  }
  .featureBox {
    flex: 1;
    text-align: center;
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width:25vw;
  }
  .featureBox:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  .featureTitle {
    font-size: 1.3rem;
    font-weight: bold;
    color: #416ea2;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  .featureDescription {
    font-size: 1rem;
    color: #555;
    line-height: 1.4;
  }
  .icon {
    font-size: 1.8rem;
    margin-right: 8px;
    vertical-align: middle;
    color: #416ea2;
  }
  .circlesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
  
  @media (max-width: 768px) {
    .ContainerForStep {
      flex-direction: column;
    }
    .enhancedSection {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
      gap: 10px;
      width:85vw;
    }
  
    .pageContainer{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .featureBox {
      margin-bottom: 15px;
      width: 80vw;
    }
  
    .circlesContainer {
      height: auto;
      padding: 15px;
      margin:10px;
    }
  }
  
  @media (max-width: 480px) {
    .stepsHeader h1 {
      font-size: 2rem;
    }
  
    .stepsHeader p {
      font-size: 0.9rem;
    }
  
    .featureTitle {
      font-size: 1rem;
    }
  
    .featureDescription {
      font-size: 0.85rem;
    }
  
    .icon {
      font-size: 1.5rem;
    }
  }
    