.content-policy{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content-text-policy {
  width: 100%;
  max-width: 1200px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 8px;
}

.main-container-policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  flex-grow: 1;
}

@media (max-width: 600px) {
  .content-policy {
    background-image: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .content-text-policy {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.85);
    width:85vw;
  }
  
  .content-text-policy h1, .content-text-policy h5 {
    font-size: 1.5rem;
  }
  
  .content-text-policy h6, .content-text-policy p {
    font-size: 1rem;
  }
}


