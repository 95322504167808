.content-delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 100%;
    height: 100%;
  }
  .content-text-delete {
    width:75vw;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px;
    border-radius: 8px;
    height: auto;
  }
  
  .main-container-delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    flex-grow: 1;
  }
  
  @media (max-width: 600px) {
    .content-delete {
      background-image: none;
      display: flex;
      justify-content:flex-start;
      align-items: center;
      padding: 0px;
      height: auto;
      max-width: 800px;
    }
    .content-text-delete {
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.85);
      width:85vw;
    }
    
    .content-text-delete h1, .content-text-delete h5 {
      font-size: 1.5rem;
    }
    
    .content-text-delete h6, .content-text-delete p {
      font-size: 1rem;
    }
    .footer-container-delete {
      margin-top: 250px;
    }
  }