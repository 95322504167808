@import '../styles/design-tokens'; /* Adjust path if needed */

/* Pre-header styles */
.preHeader {
  background-color: $pre-header-bg; /* Use SCSS variable */
  padding: 12px 0;
  color: $text-light; /* Use SCSS variable */
}
.logo{
  cursor: pointer;
  gap: 10px;
}
.preHeaderContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.contactInfo {
  display: flex;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.appTitle {
  font-weight:600;
  color: #34567A; 
}
.contactInfo a {
  color: $text-light; /* Use SCSS variable */
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
}
.downloadButtons{
 
}
.downloadButtons img{
  width: 1000px;
  height: 10px;
  cursor: pointer;
}

.socialMedia {
  display: flex;
  gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-end; /* Align to the right */
}

.socialMedia a {
  color: $text-light; /* Use SCSS variable */
  text-decoration: none;
  font-size: 16px; /* Icon size */
  transition: $transition; /* Use SCSS variable */
}

.socialMedia a i {
  font-size: 18px; /* Adjust icon size */
}

.socialMedia a:hover {
  color: $primary-color; /* Use SCSS variable */
}

/* Main header styles */
.header {
  background: $header-bg; /* Use SCSS variable */
  box-shadow: $header-shadow; /* Use SCSS variable */
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: $transition; /* Use SCSS variable */
}

.headerSticky {
  padding: 8px 0;
  border-radius: 5px;
  width:95%;
  align-self: center;
  top: 10px;
  margin: 0 auto;
}

.headerContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-height: 40px;
  width: auto;
}

.mainNav {
  display: flex;
  gap: 30px;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
/* Capsule-style Navigation Links */
.navLink {
  padding: 10px 25px; /* Adjust padding for a smooth and balanced look */
  background-color: transparent; /* Default background is transparent */
  color: $text-dark; /* Default text color */
  border: 2px solid transparent; /* Transparent border to match capsule style */
  border-radius: 30px; /* Full rounded corners for a capsule look */
  text-decoration: none; /* Remove underline */
  font-size: 16px; /* Font size for readability */
  font-weight: 500; /* Normal font weight */
  text-align: center; /* Center the text */
  display: inline-block; /* Ensure proper button-like behavior */
  transition: all 0.3s ease-in-out; /* Smooth transition for color, background, and transform */
}

.navLink:hover,
.navLink.active {
  background-color: $primary-color; /* Background color on hover */
  color: $text-light; /* Change text color on hover */
  border-color: $primary-color; /* Optional: border color change for emphasis */
  transform: scale(1.05); /* Slightly enlarge for a "pop" effect */
}

.navLink:focus {
  outline: none; /* Remove focus outline */
  box-shadow: 0 0 0 2px rgba($primary-color, 0.5); /* Optional: subtle focus effect */
}




/* Mobile menu styles */
.menuTrigger {
  background-color: transparent; /* Transparent background for better integration */
  border: none; /* Removes default button borders */
  cursor: pointer; /* Indicates interactivity */
  display: flex; /* Aligns icons neatly */
  align-items: center; /* Centers the icon vertically */
  justify-content: center; /* Centers the icon horizontally */
  padding: 8px; /* Adds spacing around the icon */
  border-radius: 50%; /* Makes the button circular */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effect */
}

.menuTrigger:focus {
  outline: 2px solid #84b6ec; /* Accessibility focus outline */
  outline-offset: 4px;
}

.menuTrigger:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Slight background highlight on hover */
  transform: scale(1.1); /* Slight scaling effect */
}
.downloadButtons {
  display: flex;
  flex-direction: row; /* Ensure buttons are side by side */
  gap: 10px; /* Add some space between buttons */
  max-height: 60px; /* Restrict the height to maintain compactness */
}

/* Adjust the button styles */
.downloadButtons a img {
  height: 60px; /* Maintain aspect ratio */
  width:120px;
  object-fit: cover;
}

.downloadButtons img:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}
/* Responsive styles */
@media (max-width: 991px) {
  .mainNav {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    padding: 20px;
    box-shadow: $header-shadow; 
    background-color: $header-bg;
  }

  .mainNav.active {
    display: flex;
  }

  .menuTrigger {
    display: block;
  }

  .preHeaderContent {
    flex-direction: column;
    gap: 10px;
  }

  .contactInfo {
    flex-direction: column;
    align-items: center;
  }
 
  .socialMedia {
    justify-content: center; /* Center icons on mobile */
  }
  .downloadButtons a img {
    width:80px;
  }
}



