.content-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 100%;
    background-image: url("../assets/hero.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh;
  }
  
  .content-text-contact {
    width:75vw;
    max-width: 800px;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .main-container-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    flex-grow: 1;
  }
  .contact-form {
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: white;
  }  
  @media (max-width: 600px) {
    .content-contact {
      background-image: none;
      display: flex;
      justify-content:flex-start;
      align-items: center;
      padding: 0px;
    }
    .content-text-contact {
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.85);
      width:85vw;
    }
    
    .content-text-contact h1, .content-text-contact h5 {
      font-size: 1.5rem;
    }
    
    .content-text-contact h6, .content-text-contact p {
      font-size: 1rem;
    }
  .content-contact{
    margin-top: 100px;
  }
  .contact-form{
    margin-top: -200px;
  }
  }