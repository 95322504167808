.circleContainer {
    position: relative;
    width: 400px;
    height: 400px;
  }
  
  .circleLarge, .circleMedium, .circleSmall {
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);

  }
  .circleLarge {
    width: 350px;
    height: 350px;
    background-color: #a8d0f7 ;
    opacity: 0.5;
    z-index: 1;
  }
  .circleMedium {
    width: 300px;
    height: 300px;
    background-color: #416ea2;
    z-index: 2;
    opacity: 0.7;
  }
  
  .circleSmall {
    width: 250px;
    height: 250px;
    background-color: #264b70 ;
    z-index: 3;
  }
  
  .animate {
    animation: popIn 0.5s ease-out forwards;
  }
  
  .photo {
    width:300 px;
    height: 500px;
    z-index: 5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
  .animatePic{
    animation: fadeIn 1.2s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% {
      transform: translate(-50%, -50%) scale(0.7);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
  @keyframes popIn {
    0% {
      transform: translate(-50%, -50%) scale(0.7);
      opacity: 0;
    }
    60% {
      opacity: 0.7;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
   @media only screen and (max-width: 600px) {
    .circleContainer {
      width: 280px;
      height: 280px;
    }
  
    .circleLarge {
      width: 280px;
      height: 280px;
    }
  
    .circleMedium {
      width: 240px;
      height: 240px;
    }
  
    .circleSmall {
      width: 200px;
      height: 200px;
    }
    .photo{
      width:200px;
      height: 380px;
    }
  }
  
  
  