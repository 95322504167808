/* Design Tokens */
$primary-color: #4da6e7;
$text-light: #ffffff;
$text-dark: #2a2a2a;
$pre-header-bg: #212529;
$header-bg: #ffffff;
$footer-bg: #212529;
$header-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
$footer-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
$transition: all 0.3s ease;


