
.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressBarContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
  
}
.progressBar {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 6px;
  background: #e0e0e0;
  border-radius: 10px;
  z-index: 1;
}

.progressIndicator {
  position: absolute;
  top: 50%;
  left: 0;
  height: 6px;
  background: linear-gradient(90deg, #4caf50, #8bc34a);
  border-radius: 10px;
  z-index: 2;
  transition: width 0.4s ease-in-out;
}

.stepMarker {
  position: relative;
  z-index: 3;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease, border-color 0.3s ease;
}

.stepMarker.completed {
  background: #4caf50;
  border-color: #4caf50;
  color: #fff;
}

.stepMarker.active {
  background: #2196f3;
  border-color: #2196f3;
  color: #fff;
}
.stepsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.stepWrapper {
  margin: 10px 0;
}
.stepTitle {
  padding: 10px 15px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  transition: transform 0.3s ease, background 0.3s ease;
}

.stepDetails {
  margin-top: 10px;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.4s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 600px) {
  .stepTitle{
    width:85%;
    font-size: small;
    margin: 0 auto;
  }
  .stepDetails{
    width:90%;
    font-size: small;
    margin: 0 auto;
  }
.progressBarContainer{
  width: 90vw;

}
.container{
  width: 90vw;
  margin: 0 auto;
  padding: 0px;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
}
}